

export const baseURL = "https://propayment.easycrypto.space/api/api.php"

const appAPI = {
    async getPayments(token) {
        try {
            const res = await fetch(`${baseURL}`, {
                    method: "POST",
                    body: JSON.stringify({
                        "action": "allpaylist",
                        api_key: token
                    }),
                    headers: {
                        "Content-Type":"application/json",
                        "Authorization": `Bearer ${token}`
                    }
                }
            ).then(r => r.json())
            return res
        } catch (e) {
            console.log(e)
            return e
        }
    },
    async getCourse(inValue, outValue, token, pid, order_id) {
        try {
            const res = await fetch(`${baseURL}`, {
                    method: "POST",
                    body: JSON.stringify({
                        "action": "course",
                        "in": inValue,
                        "out": outValue,
                        api_key: token,
                        pid: pid,
                        order_id: order_id
                    }),
                    headers: {
                        "Content-Type":"application/json",
                        "Authorization": `Bearer ${token}`
                    }
                }
            ).then(r => r.json())
            return res
        } catch (e) {
            console.log(e)
            return e
        }
    },
    async getUserInfo(id, token) {
        try {
            const res = await fetch(`${baseURL}`, {
                    method: "POST",
                    body: JSON.stringify({
                        "action": "userinfo",
                        "pid": id,
                        api_key: token
                    }),
                    headers: {
                        "Content-Type":"application/json",
                        "Authorization": `Bearer ${token}`
                    }
                }
            ).then(r => r.json())
            return res
        } catch (e) {
            console.log(e)
            return e
        }
    },
    async getRequizites(type, token, orderId, pid) {
        try {
            const res = await fetch(`${baseURL}`, {
                    method: "POST",
                    body: JSON.stringify({
                        "action": "requisites",
                        "type": type,
                        api_key: token,
                        order_id: orderId,
                        pid: pid
                    }),
                    headers: {
                        "Content-Type":"application/json",
                        "Authorization": `Bearer ${token}`
                    }
                }
            ).then(r => r.json())
            return res
        } catch (e) {
            console.log(e)
            return e
        }
    },
    async getShopInfo(id, token) {
        try {
            const res = await fetch(`${baseURL}`, {
                    method: "POST",
                    body: JSON.stringify({
                        "action": "shopinfo",
                        "name": id,
                        api_key: token
                    }),
                    headers: {
                        "Content-Type":"application/json",
                        "Authorization": `Bearer ${token}`
                    }
                }
            ).then(r => r.json())
            return res
        } catch (e) {
            console.log(e)
            return e
        }
    },
    async postOrder(data, token) {
        try {
            const res = await fetch(`${baseURL}`, {
                    method: "POST",
                    body: JSON.stringify({
                        ...data,
                        api_key: token
                    }),
                    headers: {
                        "Content-Type":"application/json",
                        "Authorization": `Bearer ${token}`
                    }
                }
            ).then(r => r.json())
            return res
        } catch (e) {
            console.log(e)
            return e
        }
    },
    async cancelOrder(data) {
        try {
            const res = await fetch(`${baseURL}`, {
                    method: "POST",
                    body: JSON.stringify({
                        ...data
                    }),
                    headers: {
                        "Content-Type":"application/json"
                    }
                }
            ).then(r => r.json())
            return res
        } catch (e) {
            console.log(e)
            return e
        }
    },
    async getInfo(data, token) {
        try {
            const res = await fetch(`${baseURL}`, {
                    method: "POST",
                    body: JSON.stringify({
                        ...data
                    }),
                    headers: {
                        "Content-Type":"application/json",
                        "Authorization": `Bearer ${token}`
                    }
                }
            ).then(r => r.json())
            return res
        } catch (e) {
            console.log(e)
            return e
        }
    }
}

export default appAPI