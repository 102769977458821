import React, { useState, useEffect } from "react";
import splitNumber from "../helpers/splitNumber";
import toast from "react-hot-toast";
import appAPI from "./../api/service";
import { v4 } from "uuid";
import { DateTime } from "luxon";
import Done from "./done";

function Card({
  amount,
  course,
  requizites,
  paymentMethod,
  orderId,
  shopInfo,
  amountWithCourse,
  chatId,
  shopId,
  requizitesreqPid,
  requizitesreqTime,
  currency,
  toCurrency,
  apiKey,
  redirectUrl,
  pid,
  cancelOrder,
  outTime,
  requiziteBack,
  backOrderId
}) {

  const moscowTime = DateTime.now().setZone("Europe/Moscow");
  const currentTime = parseInt(moscowTime.toSeconds());
  //const currentTime = parseInt(Date.now() / 1000);
  const elapsedTime = requizitesreqTime ? currentTime - requizitesreqTime : 0;
  const initialTime = (outTime && Number(outTime) > 0) ? 60 * Number(outTime) - elapsedTime : null;
  const [timeLeft, setTimeLeft] = useState(initialTime > 0 ? initialTime : 0);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [image, setImage] = useState(null);

  const [modalActive, setModalActive] = useState(true)

  useEffect(() => {
    let timer;
    const checkTimeValidity = () => {
      if (initialTime && initialTime <= 0) {
        cancelOrder(-2);
      }
    };

    checkTimeValidity();

    if (initialTime && initialTime > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          const newTime = prevTime - 1;
          if (newTime <= 0) {
            clearInterval(timer);
            cancelOrder(-2);
            return 0;
          }
          return newTime;
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [initialTime, cancelOrder]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  const handleSubmit = async () => {
    if (loading === true) return;
    setLoading(true);
    const data = {
      action: "createpayment",
      userId: chatId ? chatId : 0,
      shopId: shopId,
      type: paymentMethod,
      paymentType: String(currency).toLowerCase(),
      exchangeRate: course[String(toCurrency).toLowerCase()],
      exchangeAmount: amountWithCourse,
      exchangeCurrency: String(toCurrency).toLowerCase(),
      receiveAmount: amount,
      receiveCurrency: String(currency).toLowerCase(),
      check: image ? image.img : null,
      comment: v4().slice(0, 6),
      requisites: requiziteBack,
      reqvPid: requizitesreqPid,
      pid: pid
    };

    try {
      const res = await appAPI.postOrder(data, apiKey);

      if (res.error) {
        return toast.error(res.error);
      } else {
        if (res.status === "success") {
          if(window.Telegram.WebApp.initData) window.Telegram.WebApp.close()
          setResult(true);
          if (shopInfo.redirect_url) {
            //return (window.location.href = shopInfo.redirect_url);
          }
          if (redirectUrl) {
            //return (window.location.href = redirectUrl);
          }
        }
      }
    } catch (e) {
      console.log(e);
      return toast.error("Try again later")
    } finally {
      setLoading(false);
    }
  };

  const handleSetImage = (file) => {
    if (!file) return;
    const fileReader = new FileReader();

    fileReader.onload = () => {
      setImage({ img: fileReader.result, file });
    };

    fileReader.onerror = (error) => {
      console.log(error);
      return toast.error("Ошибка при загрузке файла");
    };
    fileReader.readAsDataURL(file);
  };

  const handleCopyRequsites = () => {
    console.log(requizites)
    navigator.clipboard
      .writeText(/*paymentMethod !== "СБП" ? requizites : requizites.Phone*/ paymentMethod !== "СБП" ?  (requizites.split("\n")[0] ? requizites.split("\n")[0] : requizites) : (requizites.Phone.split("\n")[0] ? requizites.Phone.split("\n")[0] : requizites.Phone) )
      .then(() => toast.success("Реквизиты скопированы!"))
      .catch(() => toast.error("Ошибка при копировании реквизитов"));
  };


  return (
    <>
      {paymentMethod === "СБП" && modalActive !== false && <>
        <div className="modal">
          <div className="modal__block">
            <div className="modal__text">Проверяйте выбранный банк при оплате! При отправке в другой банк вы потеряете свои деньги!!!
            </div>
            <div className="modal__btn" onClick={() => setModalActive(false)}>Подтверждаю</div>
          </div>
        </div>
        </>}
      {result === null ? (
        <>
          <div className="sum__text">Сумма</div>
          <div className="sum__value" style={{ marginBottom: "15px" }}>
            {splitNumber(amountWithCourse)} {String(toCurrency).toLowerCase() === "rub" ? "₽" : String(toCurrency).toLowerCase() === "usd" || String(toCurrency).toLowerCase() === "usdt" ? "$" : String(toCurrency).toLowerCase()}
          </div>
          {initialTime && (
            <div className="row__data time">
              <div className="row__data__value" style={{ fontSize: "15px" }}>
                Вы можете оплатить в течении
              </div>
              <div className="row__data__key" style={{ fontSize: "15px" }}>
                {formatTime(timeLeft)}
              </div>
            </div>
          )}
          <div className="row__data">
            <div className="row__data__key">Магазин</div>
            <div className="row__data__value"style={{fontSize: String(shopInfo.name).length > 15 ? "15px" : "20px" }}>{shopInfo.name}</div>
          </div>
          <div className="row__data">
            <div className="row__data__key">Заказ</div>
            <div className="row__data__value"style={{fontSize: String(orderId).length > 15 ? "11px" : "14px" }}>{orderId}</div>
          </div>
          <div className="info__text">
            <span>1.</span> Сделайте перевод указанной суммы по номеру указанному ниже
          </div>
          <div className="credit__card" onClick={handleCopyRequsites}>
            <img src="/copy.svg" className="copy__icon" />
            {paymentMethod === "Карта" && requizites && <div className="bank__name">Карта</div>}
            {paymentMethod === "СБП" && requizites.Bank && <div className="bank__name">{requizites.Bank}</div>}
            {paymentMethod === "Карта" && requizites && <div className="credit__card__info">{requizites}</div>}
            {paymentMethod === "СБП" && requizites.Phone && <div className="credit__card__info">{requizites.Phone}</div>}
            {paymentMethod === "СБП" && requizites.Name && <div className="credit__card__info">{requizites.Name}</div>}
          </div>
          {paymentMethod === "СБП" && <div className="red__text">⚠️ При переводе в другой банк вы можете потерять свои средства!</div>}
          <div className="info__text">
            <span>2.</span> Прикрепите чек
          </div>
          <label className="photo">
            {image ? (
              <div className="photo__data">
                Чек прикреплен <img src="/mark.svg" />
              </div>
            ) : (
              <div className="photo__data">
                Загрузить фото/документ {" "}
                <svg width="17" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.55556 0C5.36401 0 5.18031 0.0814145 5.04486 0.226324L0.711532 4.86269C0.576093 5.00761 0.5 5.20415 0.5 5.40909V14.6818C0.5 15.9621 1.47005 17 2.66667 17H11.3333C12.53 17 13.5 15.9621 13.5 14.6818V2.31818C13.5 1.03789 12.53 0 11.3333 0H5.55556ZM6.27778 1.54545H11.3333C11.7322 1.54545 12.0556 1.89142 12.0556 2.31818V14.6818C12.0556 15.1086 11.7322 15.4545 11.3333 15.4545H2.66667C2.2678 15.4545 1.94444 15.1086 1.94444 14.6818V6.18182H5.55556C5.95444 6.18182 6.27778 5.83585 6.27778 5.40909V1.54545ZM4.83333 4.63636H2.96582L4.83333 2.63825V4.63636Z"
                    fill="transparent"
                  />
                </svg>
              </div>
            )}
            <input
              type="file"
              accept=".jpg,.jpeg,.jpg,.png,.pdf,.doc,.docx"
              style={{ display: "none" }}
              onChange={(event) => {
                const file = event.target.files[0];
                if (file) {
                  const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
                  const acceptedDocTypes = [
                    'application/pdf',
                    'application/msword',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                  ];

                  const fileType = file.type.toLowerCase();
                  const fileExtension = file.name.split('.').pop().toLowerCase();
                  const acceptedImageExtensions = ['jpeg', 'jpg', 'png'];

                  if (
                    (acceptedImageTypes.includes(fileType) && acceptedImageExtensions.includes(fileExtension)) ||
                    acceptedDocTypes.includes(fileType)
                  ) {
                    handleSetImage(file);
                  } else {
                    return toast.error("Неподдерживаемый тип файла");
                  }
                }
              }}
            />
          </label>
          <div className="info__text">
            <span>3.</span> После перевода нажмите кнопку “Оплата завершена”
          </div>
          {image ? (
            loading === true ? (
              <div className="button">
                Отправляем заявку...
              </div>
            ) : (
              <div className="button" onClick={() => handleSubmit()}>
                Завершить оплату
              </div>
            )
          ) : (
            <div className="button" onClick={() => toast.error("Прикрепите чек")} style={{ background: "lightgray" }}>
              Завершить оплату
            </div>
          )}

          <div
            className="cancel"
            style={{ marginTop: "30px" }}
            onClick={() => cancelOrder(-1)}
          >
            Отменить платеж
          </div>

          <div className="help__text_ord">Если у вас возникли проблемы с оплатой обратитесь в поддержку ⬇️<br /><br /><span onClick={() => window.open(`https://t.me/CHANGE_SUPPORT_01_BOT?start=${shopId}${backOrderId && `_${backOrderId}`}`)}>НАЖМИТЕ ЗДЕСЬ! </span>⬅️ (Поддержка)</div>
        </>
      ) : (
        <>
        <Done  backOrderId={backOrderId} shopId={shopId} text={"Ваша заявка принята, ожидайте поступление"} cardPage/>
        {/*<div className="done__block">
          <div></div>
          <div className="done__text"></div>
          <div className="help__text_ord">Если у вас возникли проблемы с оплатой обратитесь в поддержку ⬇️<br /><br /><span onClick={() => window.open(`https://t.me/CHANGE_SUPPORT_01_BOT?start=${shopId}`)}>НАЖМИТЕ ЗДЕСЬ! </span>⬅️ (Поддержка)</div>
        </div>*/}
        </>
      )}
    </>
  );
}

export default Card;
